import React, { useState, useEffect } from "react";
import { getAllNewsletters, deleteNewsletter } from "./../../services/newsletterServices";
import { useUser } from "./../../contexts/UserContext";
import { getRoleFromRoleId } from "./../../utils/roleHelper";
import useScrollToTop from "./../../hooks/useScrollToTop";
import ScrollIndicator from "./../../hooks/ScrollIndicator";
import InfoBubble from "./../../components/InfoBubble/InfoBubble";
import Button from "./../../components/Button/Button";
import ButtonEdit from "./../../components/Button/ButtonEdit";
import ButtonDelete from "./../../components/Button/ButtonDelete";
import CreateNewsletterModal from "./CreateNewsletterModal";
import EditNewsletterModal from "./EditNewsletterModal";
import { toast, ToastContainer } from "react-toastify";

const NewslettersPage = () => {
  useScrollToTop();

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [newsletters, setNewsletters] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  // Fonction pour vérifier les permissions
  const canModifyNewsletter = () => {
    return userRole === "Administrateur" || userRole === "Bureau";
  };

  // Charger les newsletters depuis l'API et les trier
  const fetchNewsletters = async () => {
    try {
      const data = await getAllNewsletters();
      const sortedData = data.sort((a, b) => {
        if (b.annee_newsletter !== a.annee_newsletter) {
          return b.annee_newsletter - a.annee_newsletter;
        }
        const moisOrder = [
          "JANVIER", "FÉVRIER", "MARS", "AVRIL", "MAI", "JUIN",
          "JUILLET", "AOÛT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DÉCEMBRE"
        ];
        return (
          moisOrder.indexOf(b.mois_newsletter.toUpperCase()) -
          moisOrder.indexOf(a.mois_newsletter.toUpperCase())
        );
      });
      setNewsletters(sortedData);
    } catch (error) {
      console.error("Erreur lors de la récupération des newsletters:", error);
    }
  };

  // Appel de fetchNewsletters au chargement de la page
  useEffect(() => {
    fetchNewsletters();
  }, []);

  // Fonction pour supprimer une newsletter avec fenêtre de confirmation
  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette newsletter ?")) {
      try {
        await deleteNewsletter(id);
        await fetchNewsletters();

        // Notification de suppression réussie
        toast.success("La newsletter a été supprimée avec succès.");
      } catch (error) {
        console.error("Erreur lors de la suppression de la newsletter:", error);
        toast.error("Erreur lors de la suppression de la newsletter.");
      }
    }
  };

  // Fonction pour ouvrir la modale d'édition
  const handleEdit = (newsletter) => {
    setSelectedNewsletter(newsletter);
    setShowEditModal(true);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Newsletters
      </h2>
      <InfoBubble title="Bienvenue sur la page des Newsletters">
        <p>
          Découvrez toutes les newsletters publiées par le Comité Départemental
          de Judo, triées par date, de la plus récente à la plus ancienne.
        </p>
      </InfoBubble>

      {/* Bouton pour créer une newsletter visible uniquement pour Administrateur et Bureau */}
      {canModifyNewsletter() && (
        <div className="flex justify-center mb-6">
          <Button
            text="Créer une Newsletter"
            onClick={() => setShowCreateModal(true)}
            className="btn-primary"
          />
        </div>
      )}

      {/* Liste des newsletters triées */}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 p-4 gap-6 mb-6 max-w-[1280px] mx-auto">
        {newsletters.length > 0 ? (
          newsletters.map((newsletter, index) => (
            <div
              key={index}
              className="relative w-full sm:w-64 h-64 bg-white rounded-lg shadow-lg overflow-hidden flex flex-col items-center justify-center"
            >
              {/* Logo en filigrane */}
              <div
                className="absolute inset-0 opacity-20"
                style={{
                  backgroundImage: `url('https://res.cloudinary.com/dw2x1voyb/image/upload/v1721292835/logo_cd33_mbm9le.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              {/* Boutons de suppression et d'édition pour Administrateur et Bureau */}
              {canModifyNewsletter() && (
                <div className="absolute top-2 right-2 flex space-x-2 z-10">
                  <ButtonDelete
                    onClick={() => handleDelete(newsletter.id)}
                    hoverLabel="Supprimer la newsletter"
                  />
                  <ButtonEdit
                    onClick={() => handleEdit(newsletter)}
                    hoverLabel="Éditer la newsletter"
                  />
                </div>
              )}
              <h3 className="relative z-10 text-2xl font-montserrat text-gray-800 dark:text-gray-800 font-bold mb-2">
                NEWSLETTER
              </h3>
              <p className="relative z-10 text-lg italic text-gray-100 dark:text-gray-100 mb-4 bg-gray-800 rounded-md p-2">
                {`${newsletter.mois_newsletter} ${newsletter.annee_newsletter}`}
              </p>
              <Button
                text="Voir"
                onClick={() =>
                  window.open(newsletter.lien_newsletter, "_blank")
                }
                className="relative z-10"
                hoverLabel="Voir la newsletter"
              />
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">
            Aucune newsletter disponible.
          </p>
        )}
      </div>

      {/* Modales pour créer et éditer une newsletter */}
      {showCreateModal && (
        <CreateNewsletterModal
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          refreshList={fetchNewsletters}
        />
      )}
      {showEditModal && selectedNewsletter && (
        <EditNewsletterModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          refreshList={fetchNewsletters}
          newsletter={selectedNewsletter}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default NewslettersPage;
