import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";

// Contexts
import { UserProvider } from "./contexts/UserContext";
import { MessageProvider } from "./contexts/MessageContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { ContactFormProvider } from "./contexts/ContactFormContext";
import PrimaryColorProvider from "./contexts/PrimaryColorContext";

// Composants
import Navbar from "./pages/Navbar/Navbar";
import Footer from "./pages/Footer/Footer";

// Pages
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Profile from "./pages/Profile/Profile";
import UserListing from "./pages/UserListing/UserListing";
import GaleriePage from "./pages/GaleriePage/GaleriePage";
import PhotoPage from "./pages/PhotoPage/PhotoPage";
import ListingClubs from "./pages/ListingClubs/ListingClubs";
import ArbitrageJudo from "./pages/ArbitrageJudo/ArbitrageJudo";
import LexiquePage from "./pages/LexiquePage/LexiquePage";
import TechniquesView from "./pages/TechniquesView/TechniquesView";
import VieClubPage from "./pages/VieClubPage/VieClubPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import AnnoncesPage from "./pages/AnnoncesPage/AnnoncesPage";
import PartenairesKataPage from "./pages/PartenairesKataPage/PartenairesKataPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import CalendarPage from "./pages/CalendarPage/CalendarPage";
import ActeursPage from "./pages/ActeursPage/ActeursPage";
import GroupesPage from "./pages/GroupesPage/GroupesPage";
import InventairePage from "./pages/InventairePage/InventairePage";
import ToDoListPage from "./pages/ToDoListPage/ToDoListPage";
import ResultatsPage from "./pages/ResultatsPage/ResultatsPage";
import ProposeEventPage from "./pages/ProposeEventPage/ProposeEventPage";
import MessagesPage from "./pages/MessagesPage/MessagesPage";
import CommissionsPage from "./pages/CommissionsPage/CommissionsPage";
import CommissionArticlesPage from "./pages/CommissionsPage/CommissionArticlesPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import DashboardUser from "./pages/DashboardUser/DashboardUser";
import InscriptionPage from "./pages/InscriptionPage/InscriptionPage";
import OfficielsPage from "./pages/OfficielsPage/OfficielsPage";
import ConvocationsPage from "./pages/ConvocationsPage/ConvocationsPage";
import ConvocationsAdminPage from "./pages/ConvocationsAdminPage/ConvocationsAdminPage";
import ConvocationByEventPage from "./pages/ConvocationsAdminPage/ConvocationByEventPage";
import NotationPage from "./pages/NotationPage/NotationPage";
import ReglementArbitragePage from "./pages/ReglementArbitragePage/ReglementArbitragePage";
import QuizzVideoPage from "./pages/QuizzVideoPage/QuizzVideoPage";
import NewsResponsablesPage from "./pages/NewsPageResponsables/NewsPageResponsables";
import NewslettersPage from "./pages/Newsletter/Newsletter";
import JudoScorePage from "./pages/JudoScorePage/JudoScorePage";

// Documents
import ReglementInterieur from "./pages/Documents/ReglementInterieur";
import ConditionsUtilisationPage from "./pages/rgpd/ConditionsUtilisationPage.jsx";
import PolitiqueConfidentialitePage from "./pages/rgpd/PolitiqueConfidentialitePage.jsx";
import FaqPage from "./pages/rgpd/FaqPage.jsx";
import MentionsLegalesPage from "./pages/rgpd/MentionsLegalesPage.jsx";

// CSS
import "./App.css";

function App() {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getLocalStorageItem = (key) => {
      try {
        console.log("Tentative de lecture du localStorage pour la clé:", key);
        const item = localStorage.getItem(key);
        console.log("Valeur obtenue:", item);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.error(
          "Erreur lors de la lecture du localStorage pour la clé",
          key,
          ":",
          error
        );
        return null;
      }
    };

    const storedUserInfo = getLocalStorageItem("userInfo");
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    } else {
      console.error(
        "Les informations de l'utilisateur stockées sont introuvables ou corrompues."
      );
      // Actions supplémentaires ici, comme une redirection ou une récupération depuis un serveur
    }
  }, []);

  const RequireAuth = ({ children, roles }) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")); // Assure-toi que userInfo est stocké dans localStorage
    const location = useLocation();

    if (!userInfo) {
      return <div>Chargement...</div>; // Ou un composant de chargement plus sophistiqué
    }

    if (roles && !roles.includes(userInfo.role_id)) {
      // Rôle non autorisé donc redirection vers une page d'erreur ou d'accueil
      return <Navigate to="/unauthorized" replace />;
    }

    return children;
  };

  // Pour l'exemple, nous allons simuler un utilisateur connecté
  const user = {
    id: 1,
    nom: "Doe",
    prenom: "John",
    email: "JohnDoe@gmail.com",
    photoURL: "path_to_photo.jpg", // Remplace par le chemin réel de la photo
    role_id: 1, // 1 = Administrateur
    userId: 1,
  };

  return (
    <UserProvider>
      <Router>
        <MessageProvider>
          <NotificationProvider>
            <ContactFormProvider>
              <PrimaryColorProvider>
                <div className="flex flex-col min-h-screen bg-base-200 dark:bg-gray-600 dark:text-gray-200">
                  <Navbar
                    user={{
                      nom: userInfo?.nom ?? "Chargement...",
                      prenom: userInfo?.prenom ?? "",
                      role_id: userInfo?.role_id ?? 0,
                      photoURL: userInfo?.photoURL ?? "",
                      userId: userInfo?.id ?? 0,
                    }}
                  />
                  <Routes>
                    {/* Route pour la page de profil */}
                    <Route
                      path="/profile"
                      element={
                        <RequireAuth roles={[1, 2, 3, 4, 5, 6, 7]}>
                          <Profile />
                        </RequireAuth>
                      }
                    />
                    {/* Page d'inscription */}
                    <Route
                      path="/inscription"
                      element={
                        <RequireAuth roles={[1, 2, 3, 4, 5, 6, 7]}>
                          <InscriptionPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page du calendrier */}
                    <Route path="/calendrier" element={<CalendarPage />} />
                    {/* Page des résultats */}
                    <Route path="/resultats" element={<ResultatsPage />} />
                    {/* Page des actualités */}
                    <Route path="/actualites" element={<NewsPage />} />
                    {/* Page de la newsletter */}
                    <Route path="/newsletter" element={<NewslettersPage />} />
                    {/* Galerie Photos */}
                    <Route path="/galerie-photos" element={<GaleriePage />} />
                    {/* Page de Photos */}
                    <Route
                      path="/galerie-photos/album/:id"
                      element={<PhotoPage />}
                    />
                    {/* Page des commissions */}
                    <Route path="/commissions" element={<CommissionsPage />} />
                    {/* Page spécifiques des commissions */}
                    <Route
                      path="/commission/:id/articles"
                      element={<CommissionArticlesPage />}
                    />
                    {/* Page de Listing des Clubs */}
                    <Route path="/listing-clubs" element={<ListingClubs />} />
                    {/* Page de la vie du club */}
                    <Route path="/vie-des-clubs" element={<VieClubPage />} />
                    {/* Page du lexique */}
                    <Route path="/lexique" element={<LexiquePage />} />
                    {/* Page d'arbitrage judo */}
                    <Route path="/arbitrage-judo" element={<ArbitrageJudo />} />
                    {/* Page de JudoScore */}
                    <Route path="/judo-score" element={<JudoScorePage />} />
                    {/* Page de visualisation des techniques */}
                    <Route path="/techniques" element={<TechniquesView />} />
                    {/* Page des partenaires kata */}
                    <Route
                      path="/partenaires-kata"
                      element={<PartenairesKataPage />}
                    />
                    
                    {/* RESPONSABLES CLUBS /////////////////////////////////////////////////////////////////////////// */}
                    {/* Page de messagerie */}
                    <Route
                      path="/messagerie"
                      element={
                        <RequireAuth roles={[1, 2, 3, 4, 5, 7]}>
                          <MessagesPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page de tableau de bord utilisateur */}
                    <Route
                      path="/tableau-de-bord"
                      element={
                        <RequireAuth roles={[1, 2, 3, 4, 5, 7]}>
                          <DashboardUser />
                        </RequireAuth>
                      }
                    />
                    {/* Page des annonces */}
                    <Route
                      path="/petites-annonces"
                      element={
                        <RequireAuth roles={[1, 2, 5, 7]}>
                          <AnnoncesPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page de proposition d'événement */}
                    <Route
                      path="/proposer-evenement"
                      element={
                        <RequireAuth roles={[1, 2, 5, 7]}>
                          <ProposeEventPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des actualités pour les responsables de clubs */}
                    <Route
                      path="/correspondance"
                      element={
                        <RequireAuth roles={[1, 2, 5, 7]}>
                          <NewsResponsablesPage />
                        </RequireAuth>
                      }
                    />
                    {/* MEMBER /////////////////////////////////////////////////////////////////////////// */}
                    {/* Page de la ToDoList */}
                    <Route
                      path="/todolist"
                      element={
                        <RequireAuth roles={[1, 2, 3, 7]}>
                          <ToDoListPage />
                        </RequireAuth>
                      }
                    />
                    {/* OFFICIELS/////////////////////////////////////////////////////////////////////////// */}
                    {/* Page des convocations */}
                    <Route
                      path="/convocations"
                      element={
                        <RequireAuth roles={[1, 2, 3, 4, 7]}>
                          <ConvocationsPage />
                        </RequireAuth>
                      }
                    />

                    {/* Page du règlement d'arbitrage */}
                    <Route
                      path="/reglement-arbitrage"
                      element={
                        <RequireAuth roles={[1, 2, 4, 7]}>
                          <ReglementArbitragePage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des quizz vidéos */}
                    <Route
                      path="/quizz-video"
                      element={
                        <RequireAuth roles={[1, 2, 4, 7]}>
                          <QuizzVideoPage />
                        </RequireAuth>
                      }
                    />
                    {/* ADMIN & BUREAU/////////////////////////////////////////////////////////////////////////// */}
                    {/* Listings Utilisateurs roles 1 et 2 */}
                    <Route
                      path="/listing-utilisateurs"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <UserListing />
                        </RequireAuth>
                      }
                    />
                    {/* Page des acteurs */}
                    <Route
                      path="/listing-acteurs"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <ActeursPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des groupes */}
                    <Route
                      path="/listing-groupes"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <GroupesPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des officiels */}
                    <Route
                      path="/listing-officiels"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <OfficielsPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des convocations pour les administrateurs */}
                    <Route
                      path="/convocations-admin"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <ConvocationsAdminPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des convocations par événement */}
                    <Route
                      path="/convocations-admin/:eventId"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <ConvocationByEventPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page Gestion des événements */}
                    <Route
                      path="/evenements"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <EventsPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des notations */}
                    <Route
                      path="/notation"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <NotationPage />
                        </RequireAuth>
                      }
                    />
                    {/* Page de l'inventaire */}
                    <Route
                      path="/inventaire"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <InventairePage />
                        </RequireAuth>
                      }
                    />
                    {/* Page des paramètres */}
                    <Route
                      path="/parametres"
                      element={
                        <RequireAuth roles={[1, 2]}>
                          <SettingsPage />
                        </RequireAuth>
                      }
                    />

                    {/* Route pour la page d'accueil du site vitrine */}
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/404" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />
                    <Route
                      path="/reglement-interieur"
                      element={<ReglementInterieur />}
                    />
                    <Route
                      path="/conditions-utilisation"
                      element={<ConditionsUtilisationPage />}
                    />
                    <Route
                      path="/politique-confidentialite"
                      element={<PolitiqueConfidentialitePage />}
                    />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route
                      path="/mentions-legales"
                      element={<MentionsLegalesPage />}
                    />
                  </Routes>
                  <Footer />
                </div>
              </PrimaryColorProvider>
            </ContactFormProvider>
          </NotificationProvider>
        </MessageProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
