import React, { useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { addNewsletter } from './../../services/newsletterServices';
import Button from './../../components/Button/Button';
import { toast } from 'react-toastify';

const CreateNewsletterModal = ({ isOpen, onClose, refreshList }) => {
  const [formData, setFormData] = useState({
    annee_newsletter: '',
    mois_newsletter: '',
    lien_newsletter: '',
  });

  // Gestion des changements de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Soumission du formulaire pour créer une nouvelle newsletter
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addNewsletter(formData);
      refreshList();
      onClose();
      toast.success('Newsletter créée avec succès');
    } catch (error) {
      console.error("Erreur lors de la création de la newsletter", error);
      toast.error("Erreur lors de la création de la newsletter");
    }
  };

  if (!isOpen) return null;

  // Liste des mois pour le champ de sélection
  const moisOptions = [
    "JANVIER", "FÉVRIER", "MARS", "AVRIL", "MAI", "JUIN",
    "JUILLET", "AOÛT", "SEPTEMBRE", "OCTOBRE", "NOVEMBRE", "DÉCEMBRE"
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-gray-800 dark:text-white p-6 rounded-lg shadow-lg w-96">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Créer une Newsletter
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2" htmlFor="annee_newsletter">
              Année :
            </label>
            <input
              type="number"
              id="annee_newsletter"
              name="annee_newsletter"
              placeholder="Ex : 2024"
              value={formData.annee_newsletter}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2" htmlFor="mois_newsletter">
              Mois :
            </label>
            <select
              id="mois_newsletter"
              name="mois_newsletter"
              value={formData.mois_newsletter}
              onChange={handleChange}
              className="select select-bordered w-full"
              required
            >
              <option value="">Sélectionnez un mois</option>
              {moisOptions.map((mois) => (
                <option key={mois} value={mois}>
                  {mois}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2" htmlFor="lien_newsletter">
              Lien de la Newsletter :
            </label>
            <input
              type="url"
              id="lien_newsletter"
              name="lien_newsletter"
              placeholder="Ex : https://www.example.com"
              value={formData.lien_newsletter}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="flex justify-center space-x-4 mt-6">
            <Button
              text="Créer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewsletterModal;
