import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/newsletter`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les newsletters
export const getAllNewsletters = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllNewsletters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des newsletters:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter une newsletter
export const addNewsletter = async (newNewsletter) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addNewsletter`,
      newNewsletter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 201)
      throw new Error("Failed to add new newsletter");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout d'une newsletter:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour modifier une newsletter avec son id
export const updateNewsletter = async (id, updatedNewsletter) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateNewsletter/${id}`,
      updatedNewsletter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update newsletter");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la mise à jour d'une newsletter:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// DELETE
// Fonction pour supprimer une newsletter avec son id
export const deleteNewsletter = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteNewsletter/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to delete newsletter");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression d'une newsletter:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};